import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

// material-ui
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import MuiBreadcrumbs from '@mui/material/Breadcrumbs'

// project import
import MainCard from '@/components/MainCard'
import { NavigationChild, NavigationItem } from '@/interfaces/GlobalInterfaces.ts'

interface BreadcrumbsProps {
    navigation: { items: NavigationItem[] }
    title: boolean
}

export default function Breadcrumbs({ navigation, title, ...others }: BreadcrumbsProps) {
    const location = useLocation()
    const [main, setMain] = useState<NavigationItem>()
    const [item, setItem] = useState<NavigationChild>()

    // set active item state
    const getCollapse = (menu: NavigationItem | NavigationChild) => {
        if ('children' in menu) {
            // Type guard to check if menu is NavigationItem
            menu.children.filter((collapse: NavigationChild) => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse)
                } else if (collapse.type && collapse.type === 'item') {
                    if (location.pathname === collapse.url) {
                        setMain(menu)
                        setItem(collapse)
                    }
                }
                return false
            })
        }
    }

    useEffect(() => {
        navigation?.items?.map((menu: NavigationItem | NavigationChild) => {
            if (menu.type && menu.type === 'group') {
                getCollapse(menu)
            }
            return false
        })
    })

    // only used for component demo breadcrumbs
    if (location.pathname === '/breadcrumbs') {
        location.pathname = '/dashboard/analytics'
    }

    let mainContent
    let itemContent
    let breadcrumbContent = <Typography />
    let itemTitle = ''

    // collapse item
    if (main && main.type === 'collapse') {
        mainContent = (
            <Typography
                component={Link}
                to={document.location.pathname}
                variant="h6"
                sx={{ textDecoration: 'none' }}
                color="textSecondary"
            >
                {main.title}
            </Typography>
        )
    }

    // items
    if (item && item.type === 'item') {
        itemTitle = item.title
        itemContent = (
            <Typography variant="subtitle1" color="textPrimary">
                {itemTitle}
            </Typography>
        )

        // main
        if (item.breadcrumbs) {
            breadcrumbContent = (
                <MainCard sx={{ mb: 3, bgcolor: 'transparent' }} {...others} content={false}>
                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={1}>
                        <Grid item>
                            <MuiBreadcrumbs aria-label="breadcrumb">
                                <Typography
                                    component={Link}
                                    to="/"
                                    color="textSecondary"
                                    variant="h6"
                                    sx={{ textDecoration: 'none' }}
                                >
                                    Home
                                </Typography>
                                {mainContent}
                                {itemContent}
                            </MuiBreadcrumbs>
                        </Grid>
                        {title && (
                            <Grid item sx={{ mt: 2 }}>
                                <Typography variant="h5">{item.title}</Typography>
                            </Grid>
                        )}
                    </Grid>
                </MainCard>
            )
        }
    }

    return breadcrumbContent
}
