import { apiV1Client } from '@/services/ApiClientService.ts'
import { User } from '@/interfaces/GlobalInterfaces.ts'

export interface LoginResponse {
    message: string
    token: string
}

export interface RegisterData {
    username: string
    email: string
    password: string
}

export class AuthRepository {
    async login(email: string, password: string): Promise<LoginResponse> {
        return apiV1Client.post<LoginResponse>('/auth/login', { email, password })
    }

    async register(data: RegisterData): Promise<User> {
        return apiV1Client.post<User>('/auth/register', data)
    }

    async logout(): Promise<void> {
        await apiV1Client.post('/auth/logout')
    }

    async resetPassword(email: string): Promise<void> {
        await apiV1Client.post('/auth/reset-password', { email })
    }

    async verifyEmail(token: string): Promise<void> {
        await apiV1Client.post('/auth/verify-email', { token })
    }

    async refreshToken(): Promise<void> {
        await apiV1Client.post('/auth/refresh-token')
    }
}
