import { useRef, useState } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import Avatar from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import Paper from '@mui/material/Paper'
import Popper from '@mui/material/Popper'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

// project import
import MainCard from '../../../../components/MainCard'
import Transitions from '../../../../components/@extended/Transitions'

// assets
import BellOutlined from '@ant-design/icons/BellOutlined'
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined'
import GiftOutlined from '@ant-design/icons/GiftOutlined'
import MessageOutlined from '@ant-design/icons/MessageOutlined'
import SettingOutlined from '@ant-design/icons/SettingOutlined'

// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem',
}

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',

    transform: 'none',
}

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

export default function Notification() {
    const theme = useTheme()
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'))

    const anchorRef = useRef(null)
    const [read, setRead] = useState(2)
    const [open, setOpen] = useState(false)
    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const iconBackColorOpen = 'grey.100'

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'transparent' }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <Badge badgeContent={read} color="primary">
                    <BellOutlined />
                </Badge>
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [matchesXs ? -5 : 0, 9] } }] }}
            >
                {({ TransitionProps }) => (
                    <Transitions
                        direction="down"
                        type="grow"
                        position={matchesXs ? 'top' : 'top-right'}
                        in={open}
                        {...TransitionProps}
                    >
                        <Paper
                            sx={{
                                boxShadow: 1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: { xs: 285, md: 420 },
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title="Notification"
                                    elevation={0}
                                    content={false}
                                    secondary={
                                        <>
                                            {read > 0 && (
                                                <Tooltip title="Mark as all read">
                                                    <IconButton color="success" size="small" onClick={() => setRead(0)}>
                                                        <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                                                    </IconButton>
                                                </Tooltip>
                                            )}
                                        </>
                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                                '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                                                '& .MuiAvatar-root': avatarSX,
                                                '& .MuiListItemSecondaryAction-root': {
                                                    ...actionSX,
                                                    position: 'relative',
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemButton selected={read > 0}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'success.main', bgcolor: 'success.lighter' }}>
                                                    <GiftOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        It&apos;s{' '}
                                                        <Typography component="span" variant="subtitle1">
                                                            Cristina danny&apos;s
                                                        </Typography>{' '}
                                                        birthday today.
                                                    </Typography>
                                                }
                                                secondary="2 min ago"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    3:00 AM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}>
                                                    <MessageOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Aida Burg
                                                        </Typography>{' '}
                                                        commented your post.
                                                    </Typography>
                                                }
                                                secondary="5 August"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    6:00 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton selected={read > 0}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ color: 'error.main', bgcolor: 'error.lighter' }}>
                                                    <SettingOutlined />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        Your Profile is Complete &nbsp;
                                                        <Typography component="span" variant="subtitle1">
                                                            60%
                                                        </Typography>{' '}
                                                    </Typography>
                                                }
                                                secondary="7 hours ago"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    2:45 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton>
                                            <ListItemAvatar>
                                                <Avatar
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: 'primary.lighter',
                                                    }}
                                                >
                                                    C
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">
                                                        <Typography component="span" variant="subtitle1">
                                                            Cristina Danny
                                                        </Typography>{' '}
                                                        invited to join{' '}
                                                        <Typography component="span" variant="subtitle1">
                                                            Meeting.
                                                        </Typography>
                                                    </Typography>
                                                }
                                                secondary="Daily scrum meeting time"
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
                                                    9:10 PM
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton>
                                        <Divider />
                                        <ListItemButton sx={{ textAlign: 'center', py: `${12}px !important` }}>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6" color="primary">
                                                        View All
                                                    </Typography>
                                                }
                                            />
                                        </ListItemButton>
                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    )
}
