import { User } from '@/interfaces/GlobalInterfaces'
import { sdk } from '@/repositories/sdk'
import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react'

interface AuthContextType {
    user: User | null
    login: (email: string, password: string) => Promise<void>
    logout: () => Promise<void>
    isAuthenticated: () => boolean
    getToken: () => string | null
}

const AuthContext = createContext<AuthContextType | null>(null)

interface AuthProviderProps {
    children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null)
    const [token, setToken] = useState<string | null>(() =>
        localStorage.getItem(import.meta.env.VITE_TOKEN_STORAGE_KEY)
    )

    // Fetch user on mount
    useEffect(() => {
        fetchUser()
    }, [])

    // Fetch user on token change
    useEffect(() => {
        fetchUser()
    }, [token])

    const fetchUser = async () => {
        if (token) {
            try {
                const currentUser = await sdk.user.getCurrentUser()
                setUser(currentUser)
            } catch (error) {
                // If token is invalid, clear it
                localStorage.removeItem(import.meta.env.VITE_TOKEN_STORAGE_KEY)
                setToken(null)
                setUser(null)
                console.error('Error fetching user', error)
            }
        }
    }

    const login = async (email: string, password: string) => {
        const response = await sdk.auth.login(email, password)
        if (response.token) {
            localStorage.setItem(import.meta.env.VITE_TOKEN_STORAGE_KEY, response.token)
            setToken(response.token)
            const currentUser = await sdk.user.getCurrentUser()
            setUser(currentUser)
        }
    }

    const logout = async () => {
        await sdk.auth.logout()
        localStorage.removeItem(import.meta.env.VITE_TOKEN_STORAGE_KEY)
        setToken(null)
        setUser(null)
    }

    const isAuthenticated = () => user !== null
    const getToken = () => token

    return (
        <AuthContext.Provider value={{ user, login, logout, isAuthenticated, getToken }}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext)
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}
