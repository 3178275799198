import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import ThemeCustomization from './themes/index'
import ScrollTop from './components/ScrollTop'
import MainRoutes from './routes/MainRoutes'
import LoginRoutes from './routes/LoginRoutes'
import { useLocaleEffect } from './services/LocaleService'
import { LocaleProvider } from './providers/LocaleProvider'
import { MenuProvider } from '@/contexts/MenuContext'

const App: React.FC = () => {
    const isLocaleLoaded = useLocaleEffect()

    // Put MainRoutes after LoginRoutes to ensure login paths are matched first
    const router = createBrowserRouter([MainRoutes, LoginRoutes], {
        basename: import.meta.env.VITE_APP_BASE_NAME,
    })

    if (!isLocaleLoaded) {
        return <div></div>
    }

    return (
        <MenuProvider>
            <LocaleProvider>
                <ThemeCustomization>
                    <ScrollTop>
                        <RouterProvider router={router} />
                    </ScrollTop>
                </ThemeCustomization>
            </LocaleProvider>
        </MenuProvider>
    )
}

export default App
