// User Repository
import { User } from '@/interfaces/GlobalInterfaces.ts'
import { apiV1Client } from '@/services/ApiClientService.ts'

export class UserRepository {
    async getUser(id: string): Promise<User> {
        return apiV1Client.get<User>(`/users/${id}`)
    }

    async getUsers(): Promise<User[]> {
        return apiV1Client.get<User[]>('/users')
    }

    async updateUser(id: string, data: Partial<User>): Promise<User> {
        return apiV1Client.put<User>(`/users/${id}`, data)
    }

    async deleteUser(id: string): Promise<void> {
        await apiV1Client.delete(`/users/${id}`)
    }

    async createUser(data: Partial<User>): Promise<User> {
        return apiV1Client.post<User>('/users', data)
    }

    async getCurrentUser(): Promise<User> {
        return apiV1Client.get<User>('/users/me')
    }

    async updateCurrentUser(data: Partial<User>): Promise<User> {
        return apiV1Client.put<User>('/users/me', data)
    }
}
