import { useMemo } from 'react'

// material-ui
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'

// project import
import AppBarStyled from './AppBarStyled'
import HeaderContent from './HeaderContent'

import { useMenu } from '@/contexts/MenuContext'

// assets
import MenuFoldOutlined from '@ant-design/icons/MenuFoldOutlined'
import MenuUnfoldOutlined from '@ant-design/icons/MenuUnfoldOutlined'

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

export default function Header() {
    const theme = useTheme()
    const downLG = useMediaQuery(theme.breakpoints.down('lg'))

    const { isDashboardDrawerOpened, toggleDrawer } = useMenu()

    // header content
    const headerContent = useMemo(() => <HeaderContent />, [])

    const iconBackColor = 'grey.100'
    const iconBackColorOpen = 'grey.200'

    // common header
    const mainHeader = (
        <Toolbar>
            <IconButton
                disableRipple
                aria-label="open drawer"
                onClick={toggleDrawer}
                edge="start"
                color="secondary"
                sx={{
                    color: 'text.primary',
                    bgcolor: isDashboardDrawerOpened ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0, lg: -2 },
                }}
            >
                {!isDashboardDrawerOpened ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            {headerContent}
        </Toolbar>
    )

    // app-bar params
    const barStyle = {
        borderBottom: `1px solid ${theme.palette.divider}`,
        // boxShadow: theme.customShadows.z1
    }

    return (
        <>
            {!downLG ? (
                <AppBarStyled open={!!isDashboardDrawerOpened} elevation={0} sx={barStyle} color={'inherit'}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar elevation={0} sx={barStyle} color={'inherit'}>
                    {mainHeader}
                </AppBar>
            )}
        </>
    )
}
