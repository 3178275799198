import Grid from '@mui/material/Grid'
import MainCard from '@/components/MainCard'
import ComponentSkeleton from './ComponentSkeleton'
import { GraphJsonData, PerfbaseFlameGraph } from '@/components/FlameGraph/PerfbaseFlameGraph'
import { memo, useEffect, useState } from 'react'

const ComponentFlameGraph = memo(() => {
    const [data, setData] = useState<GraphJsonData | null>(null)

    useEffect(() => {
        fetch('/data3.json')
            .then((response) => response.json())
            .then((jsonData) => {
                setData(jsonData)
            })
            .catch((error) => {
                console.error('Error loading data:', error)
            })
    }, [])

    if (!data) return <>Loading...</>

    return (
        <ComponentSkeleton>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <MainCard title="Flame Graph">
                        <PerfbaseFlameGraph data={data} />
                    </MainCard>
                </Grid>
            </Grid>
        </ComponentSkeleton>
    )
})

export default ComponentFlameGraph
