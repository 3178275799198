// noinspection JSUnusedGlobalSymbols

import { select, Selection } from 'd3-selection'
import { ascending } from 'd3-array'
import { hierarchy, HierarchyRectangularNode, partition } from 'd3-hierarchy'
import { scaleLinear } from 'd3-scale'
import { easeCubic } from 'd3-ease'
import 'd3-transition'
import { Tooltip } from '@/flamechart/tooltip.ts'
import { GraphNodeData } from '@/components/FlameGraph/PerfbaseFlameGraph.tsx'

export interface StackFrame {
    name: string
    value: number
    children: StackFrame[]
    hide: boolean
    id?: string
    highlight?: boolean
    data?: StackFrame
    fade?: boolean
    d?: number
    delta?: number
    n?: string
    l?: string
    libtype?: string
}

// Extending HierarchyRectangularNode to include our optional fields
export interface FlameGraphNode extends HierarchyRectangularNode<StackFrame> {
    highlight?: boolean
    originalValue?: number
    delta?: number
    data: StackFrame & {call_count: number, wall_time_total: number, wall_time_avg: number, wall_time_min: number, wall_time_max: number, memory_usage: number, peak_memory: number }
    value: number
}

export type LabelHandler = (node: FlameGraphNode) => string
export type NameHandler = (node: FlameGraphNode) => string
export type ClickHandler = (node: FlameGraphNode) => void
export type DetailsHandler = (node: FlameGraphNode | null) => void
export type SearchHandler = (results: FlameGraphNode[], sum: number, totalValue: number) => void
export type ColorMapper = (node: FlameGraphNode, isSelected: boolean, searchString: string) => string
export type SearchMatch = (node: FlameGraphNode, term: string) => boolean

export class FlameGraph {
    public w: number = 960
    public h: number | null = null
    public c: number = 32
    public title: string = ''
    public searchString: string = ''
    public selected: FlameGraphNode | null = null
    public transitionEase: (t: number) => number = easeCubic
    public sort: boolean | ((a: StackFrame, b: StackFrame) => number) = false
    public inverted: boolean = false
    public minFrameSize: number = 0
    public selfValue: boolean = false
    public computeDelta: boolean = false
    public colorHue: string | null = null

    private selection: Selection<HTMLDivElement, FlameGraphNode, null, undefined> | null = null
    private tooltip: Tooltip | null = null
    private clickHandler: ClickHandler | null = null
    private hoverHandler: ((node: FlameGraphNode) => void) | null = null
    private resetHeightOnZoom: boolean = false
    private minHeight: number | null = null

    public getName: NameHandler = (d) => d.data.data?.n ?? d.data.name ?? ''
    public getNameFormatted: NameHandler = (d) => d.data.data?.n ?? d.data.name ?? ''
    public getValue: (d: StackFrame) => number = (d) => d.value
    public getChildren: (d: StackFrame) => StackFrame[] | undefined = (d) => d.children
    public getLibtype: (d: FlameGraphNode) => string | undefined = (d) => d.data.data?.l ?? d.data.data?.libtype
    public getDelta: (d: FlameGraphNode) => number = (d) => d.data.data?.d ?? d.data.data?.delta ?? 0


    private labelHandler: LabelHandler = (d) =>
        `${this.getName(d)} (${(100 * (d.x1 - d.x0)).toFixed(3)}%, ${this.getValue(d.data)} samples)`


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private colorMapper: ColorMapper = (_node, _isSelected, _searchString) => {
        return 'grey';
    }

    private p = partition<StackFrame>()

    constructor() {}

    public setTooltip(tooltip: Tooltip | null) {
        if (typeof tooltip === 'function') {
            this.tooltip = tooltip
        }
    }

    public setClickHandler(handler: ClickHandler | null): void {
        this.clickHandler = handler
    }

    public setHoverHandler(handler: ((node: FlameGraphNode) => void) | null): void {
        this.hoverHandler = handler
    }

    public setLabelHandler(handler: LabelHandler): void {
        this.labelHandler = handler
    }

    public setColorMapper(mapper: ColorMapper): void {
        this.colorMapper = mapper
    }

    public setResetHeightOnZoom(value: boolean): void {
        this.resetHeightOnZoom = value
    }

    public setMinHeight(value: number | null): void {
        this.minHeight = value
    }

    public render(selection: Selection<HTMLDivElement, GraphNodeData, null, undefined>): void {
        this.selection = selection.datum((data: unknown) => {
            const name = (data as { constructor?: { name?: string } })?.constructor?.name
            if (name !== 'Node') {
                const root = hierarchy<StackFrame>(data as StackFrame, this.getChildren) as FlameGraphNode
                this.adoptNode(root)
                this.reappraiseNode(root)

                root.originalValue = root.value

                if (this.computeDelta) {
                    root.eachAfter((node: FlameGraphNode) => {
                        let sum = this.getDelta(node)
                        const children = node.children
                        if (children) {
                            for (let i = children.length - 1; i >= 0; i--) {
                                sum += (children[i] as FlameGraphNode).delta || 0
                            }
                        }
                        node.delta = sum
                    })
                }

                return root
            }
            return data as FlameGraphNode
        }) as Selection<HTMLDivElement, FlameGraphNode, null, undefined>

        this.selection.each((_, i, nodes) => {
            const el = nodes[i]
            const sel = select(el)
            if (sel.select('svg').empty()) {
                const svg = sel.append('svg:svg')
                    .attr('width', this.w)
                    .attr('class', 'partition d3-flame-graph')

                // If h is set, ensure it's at least minHeight if minHeight is defined
                if (this.h) {
                    if (this.minHeight) {
                        this.h = Math.max(this.h, this.minHeight)
                    }
                    svg.attr('height', this.h)
                }

                svg.append('svg:text')
                    .attr('class', 'title')
                    .attr('text-anchor', 'middle')
                    .attr('y', '25')
                    .attr('x', this.w / 2)
                    .attr('fill', '#808080')
                    .text(this.title)

                if (this.tooltip) svg.call(this.tooltip)
            }
        })

        this.update()
    }


    public findById(id: string | number): StackFrame | null {
        if (!this.selection) return null
        let found: StackFrame | null = null
        this.selection.each((data: FlameGraphNode) => {
            if (found === null) {
                const f = this.findTree(data.data, id)
                if (f) found = f
            }
        })
        return found
    }

    public clear(): void {
        if (!this.selection) return
        this.selection.each((root: FlameGraphNode) => {
            this.clearNode(root.data)
            this.update()
        })
    }

    public zoomTo(d: FlameGraphNode): void {
        this.zoom(d)
    }

    public resetZoom(): void {
        if (!this.selection) return
        this.selection.each((root: FlameGraphNode) => {
            this.zoom(root)
        })
    }

    public merge(data: StackFrame): void {
        if (!this.selection) return
        this.resetZoom()
        this.selection.datum((root: FlameGraphNode) => {
            this.mergeData([root.data], [data])
            return root.data
        })
        this.processData()
        this.update()
    }

    public updateData(data: StackFrame): void {
        if (!this.selection) return
        if (data) {
            this.selection.datum(data)
            this.processData()
        }
        this.update()
    }

    public destroy(): void {
        if (!this.selection) return
        if (this.tooltip) {
            this.tooltip.hide()
            if (typeof this.tooltip.destroy === 'function') {
                this.tooltip.destroy()
            }
        }
        this.selection.selectAll('svg').remove()
    }

    // ----- Private/Internal Methods -----

    private show(d: FlameGraphNode) {
        d.data.fade = false
        d.data.hide = false
        if (d.children) {
            d.children.forEach((child) => this.show(child as FlameGraphNode))
        }
    }

    private hideSiblings(node: FlameGraphNode) {
        let child: FlameGraphNode | null = node
        let parent = child.parent
        while (parent) {
            const children = parent.children!
            for (const sibling of children) {
                if (sibling !== child) {
                    (sibling.data as StackFrame).hide = true
                }
            }
            child = parent
            parent = child.parent
        }
    }

    private fadeAncestors(d: FlameGraphNode) {
        if (d.parent) {
            d.parent.data.fade = true
            this.fadeAncestors(d.parent)
        }
    }

    private zoom(d: FlameGraphNode) {
        if (this.tooltip) this.tooltip.hide()
        this.hideSiblings(d)
        this.show(d)
        this.fadeAncestors(d)
        this.selected = d;
        this.update()
        if (typeof this.clickHandler === 'function') {
            this.clickHandler(d)
        }
    }

    private findTree(d: StackFrame, id: string | number): StackFrame | undefined {
        if (d.id === id) {
            return d
        } else {
            const children = this.getChildren(d)
            if (children) {
                for (const child of children) {
                    const found = this.findTree(child, id)
                    if (found) {
                        return found
                    }
                }
            }
        }
        return undefined
    }

    private clearNode(d: StackFrame) {
        d.highlight = false
        const children = this.getChildren(d)
        if (children) {
            for (const child of children) {
                this.clearNode(child)
            }
        }
    }

    private doSort(a: FlameGraphNode, b: FlameGraphNode) {
        if (typeof this.sort === 'function') {
            return this.sort(a.data, b.data)
        } else if (this.sort) {
            return ascending(this.getName(a), this.getName(b))
        }
        return 0
    }

    private filterNodes(nodeList: FlameGraphNode[], root: FlameGraphNode): FlameGraphNode[] {
        if (this.minFrameSize <= 0) return nodeList
        const kx = this.w / (root.x1 - root.x0)
        return nodeList.filter((el: FlameGraphNode) => {
            return (el.x1 - el.x0) * kx > this.minFrameSize
        })
    }

    public search(term: string): void {
        this.searchString = term
        this.update()
    }

    public update() {
        if (!this.selection) return

        this.selection.each((root: FlameGraphNode, i, nodes) => {
            // Compute layout once
            this.reappraiseNode(root)
            if (this.sort) root.sort((a, b) => this.doSort(a, b))
            this.p(root)

            // Prepare scales
            const x = scaleLinear().range([0, this.w])
            const y = scaleLinear().range([0, this.c])

            const allNodes = root.descendants()
            const descendants = this.filterNodes(allNodes, root)
            const svg = select(nodes[i]).select<SVGSVGElement>('svg')
            svg.attr('width', this.w)

            // Adjust height if needed
            if (!this.h || this.resetHeightOnZoom) {
                const maxDepth = Math.max(...descendants.map((n) => n.depth))
                this.h = (maxDepth + 3) * this.c
                if (this.minHeight && this.h < this.minHeight) this.h = this.minHeight
                svg.attr('height', this.h)
            }

            const kx = this.w / (root.x1 - root.x0)
            const widthFn = (d: FlameGraphNode) => (d.x1 - d.x0) * kx

            let g = svg
                .selectAll<SVGGElement, FlameGraphNode>('g')
                .data(descendants, (d: FlameGraphNode) => d.data.id as string)

            g.transition()
                .attr(
                    'transform',
                    (d: FlameGraphNode) => `translate(${x(d.x0)},${this.inverted ? y(d.depth) : this.h! - y(d.depth) - this.c})`
                )

            g.select('rect')
                .attr('width', widthFn)

            const node = g
                .enter()
                .append('svg:g')
                .attr(
                    'transform',
                    (d: FlameGraphNode) =>
                        `translate(${x(d.x0)},${this.inverted ? y(d.depth) : this.h! - y(d.depth) - this.c})`
                )

            node.append('svg:rect')
                .attr('width', widthFn)

            if (!this.tooltip) {
                node.append('svg:title')
            }

            node.append('foreignObject').append('xhtml:div')

            g = svg
                .selectAll<SVGGElement, FlameGraphNode>('g')
                .data(descendants, (d: FlameGraphNode) => d.data.id as string)

            g.attr('width', widthFn)
                .attr('height', this.c)
                .attr('name', (d: FlameGraphNode) => this.getName(d))
                .attr('class', 'frame')


            g.select('rect')
                .attr('height', this.c)
                .attr('fill', (d: FlameGraphNode) => {
                    return this.colorMapper(d, this.selected === d, this.searchString)
                })

            if (!this.tooltip) {
                g.select('title').text((d: FlameGraphNode) => this.labelHandler(d))
            }

            g.select('foreignObject')
                .attr('width', widthFn)
                .attr('height', this.c)
                .select('div')
                .attr('class', 'd3-flame-graph-label')
                .style('display', (d: FlameGraphNode) => (widthFn(d) < 35 ? 'none' : 'block'))
                .html((d: FlameGraphNode) => this.getNameFormatted(d))

            g.on('click', (_event: MouseEvent, d: FlameGraphNode) => {
                this.zoom(d)
            })

            g.exit().remove()

            g.on('mouseover', (event: MouseEvent, d: FlameGraphNode) => {
                if (this.tooltip) this.tooltip.show(event, d)
                if (this.hoverHandler) this.hoverHandler(d)
            }).on('mouseout', () => {
                if (this.tooltip) this.tooltip.hide()
            })
        })
    }

    private mergeData(data: StackFrame[], samples: StackFrame[]) {
        for (const sample of samples) {
            const node = data.find((element: StackFrame) => element.name === sample.name)
            if (node) {
                node.value += sample.value
                if (sample.children) {
                    if (!node.children) {
                        node.children = []
                    }
                    this.mergeData(node.children, sample.children)
                }
            } else {
                data.push(sample)
            }
        }
    }

    private forEachNode(node: StackFrame, f: (n: StackFrame) => void) {
        f(node)
        const children = node.children
        if (children) {
            const stack = [children]
            while (stack.length) {
                const ch = stack.pop()!
                for (const child of ch) {
                    f(child)
                    if (child.children) {
                        stack.push(child.children)
                    }
                }
            }
        }
    }

    private adoptNode(node: FlameGraphNode) {
        let id = 0
        this.forEachNode(node.data, (n) => {
            n.id = (id++).toString()
            if (!n.data) n.data = { children: [], hide: false, name: '', value: 0 }
        })
    }

    private reappraiseNode(root: FlameGraphNode): void {
        // Early return if root is null
        if (!root) return;

        // Initialize data structures
        const stack: FlameGraphNode[] = [];
        const included: FlameGraphNode[][] = [];
        const excluded: Set<FlameGraphNode> = new Set();
        const compoundValue = !this.selfValue;

        // Process root node
        if (root.data.hide) {
            root.value = 0;
            if (root.children?.length) {
                this.processExcludedChildren(root.children as FlameGraphNode[], excluded);
            }
        } else {
            root.value = root.data.fade ? 0 : this.getValue(root.data);
            stack.push(root);
        }

        // First pass - Process all visible nodes
        while (stack.length > 0) {
            const node = stack.pop()!;
            const children = node.children as FlameGraphNode[];

            if (!children?.length) continue;

            let childrenValue = 0;
            const visibleChildren: FlameGraphNode[] = [];

            // Process children in reverse order for better stack efficiency
            for (let i = children.length - 1; i >= 0; i--) {
                const child = children[i];
                const childData = child.data;

                if (childData.hide) {
                    child.value = 0;
                    if (child.children?.length) {
                        this.processExcludedChildren(child.children as FlameGraphNode[], excluded);
                    }
                    continue;
                }

                child.value = childData.fade ? 0 : this.getValue(childData);
                childrenValue += child.value;
                visibleChildren.push(child);
                stack.push(child);
            }

            // Update compound values
            if (compoundValue && node.value) {
                node.value -= childrenValue;
            }

            if (visibleChildren.length) {
                included.push(visibleChildren);
            }
        }

        // Postorder traversal for compound values - Using reverse iteration
        for (let i = included.length - 1; i >= 0; i--) {
            const children = included[i];
            const childrenSum = children.reduce((sum, child) => sum + child.value, 0);
            const parent = children[0].parent;
            if (parent) {
                parent.value += childrenSum;
            }
        }

        // Process excluded nodes using Set for O(1) lookups
        this.processExcludedSet(excluded);
    }

// Helper method to process excluded children
    private processExcludedChildren(children: FlameGraphNode[], excluded: Set<FlameGraphNode>): void {
        for (const child of children) {
            excluded.add(child);
            if (child.children?.length) {
                this.processExcludedChildren(child.children as FlameGraphNode[], excluded);
            }
        }
    }

// Helper method to process excluded set
    private processExcludedSet(excluded: Set<FlameGraphNode>): void {
        for (const node of excluded) {
            node.value = 0;
        }
    }

    private processData(): void {
        if (!this.selection) return;

        this.selection.datum((data: unknown): FlameGraphNode => {
            // Early return if data is already a Node
            if (this.isNodeInstance(data)) {
                return data as FlameGraphNode;
            }

            // Process new data
            return this.createAndProcessHierarchy(data as StackFrame);
        });
    }

    private isNodeInstance(data: unknown): boolean {
        return (data as { constructor?: { name?: string } })?.constructor?.name === 'Node';
    }

    private createAndProcessHierarchy(data: StackFrame): FlameGraphNode {
        // Create hierarchy and process initial node
        const root = hierarchy<StackFrame>(data, this.getChildren) as FlameGraphNode;

        // Process the node
        this.adoptNode(root);
        this.reappraiseNode(root);

        // Store original value
        root.originalValue = root.value;

        // Compute delta if needed
        if (this.computeDelta) {
            this.computeNodeDeltas(root);
        }

        return root;
    }

    private computeNodeDeltas(root: FlameGraphNode): void {
        root.eachAfter((node: FlameGraphNode) => {
            const childrenDelta = this.computeChildrenDelta(node.children);
            node.delta = this.getDelta(node) + childrenDelta;
        });
    }

    private computeChildrenDelta(children?: FlameGraphNode[]): number {
        if (!children?.length) return 0;

        return children.reduce((sum, child) => sum + (child.delta || 0), 0);
    }
}
