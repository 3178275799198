import { useMemo } from 'react'

import { useTheme } from '@mui/material/styles'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

// project import
import DrawerHeader from './DrawerHeader'
import DrawerContent from './DrawerContent'
import MiniDrawerStyled from './MiniDrawerStyled'

import { drawerWidth } from '@/config'
import { useMenu } from '@/contexts/MenuContext'

export default function MainDrawer({ window }: { window?: () => Window }) {
    const { isDashboardDrawerOpened: drawerOpen, toggleDrawer } = useMenu()

    const theme = { ...useTheme() }
    const matchDownMD = theme.breakpoints.down('lg')

    const container = window !== undefined ? () => window().document.body : undefined
    const drawerContent = useMemo(() => <DrawerContent />, [])
    const drawerHeader = useMemo(() => <DrawerHeader open={!!drawerOpen} />, [drawerOpen])

    return (
        <Box component="nav" sx={{ flexShrink: { md: 0 }, zIndex: 1200 }} aria-label="mailbox folders">
            {!matchDownMD ? (
                <MiniDrawerStyled
                    variant="permanent"
                    open={drawerOpen}
                    sx={{ '& .MuiDrawer-paper': { position: 'relative' } }}
                >
                    {drawerHeader}
                    {drawerContent}
                </MiniDrawerStyled>
            ) : (
                <Drawer
                    container={container}
                    variant="temporary"
                    open={drawerOpen}
                    onClose={toggleDrawer}
                    ModalProps={{ keepMounted: true }}
                    sx={{
                        // display: { xs: 'block', sm: 'block', lg: 'none' },
                        '& .MuiDrawer-paper': {
                            boxSizing: 'border-box',
                            width: drawerWidth,
                            borderRight: '1px solid',
                            borderRightColor: 'divider',
                            backgroundImage: 'none',
                            boxShadow: 'inherit',
                        },
                    }}
                >
                    {drawerHeader}
                    {drawerContent}
                </Drawer>
            )}
        </Box>
    )
}
