// material-ui
import useMediaQuery from '@mui/material/useMediaQuery'
import Box from '@mui/material/Box'

// project import
import Search from './Search'
import Profile from './Profile'
import Notification from './Notification'
import MobileSection from './MobileSection'

// project import
import { Theme } from '@mui/material'

// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
    const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

    return (
        <>
            {!downLG && <Search />}
            {downLG && <Box sx={{ width: '100%', ml: 1 }} />}
            <Notification />
            {!downLG && <Profile />}
            {downLG && <MobileSection />}
        </>
    )
}
