// material-ui
import { styled } from '@mui/material/styles'
import Drawer, { DrawerProps } from '@mui/material/Drawer'
import { CSSObject, Theme } from '@mui/material'

// project import
import { drawerWidth } from '@/config'

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    borderRight: '1px solid',
    borderRightColor: theme.palette.divider,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0,
    borderRight: 'none',
})

// Update the interface to extend DrawerProps
interface MiniDrawerProps extends DrawerProps {
    open?: boolean
}

const MiniDrawerStyled = styled(Drawer)<MiniDrawerProps>(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}))

export default MiniDrawerStyled
