import React, { memo, useEffect, useRef, useState } from 'react'
import { select } from 'd3-selection'
import { FlameGraph, FlameGraphNode } from '@/flamechart/flamegraph.ts'
import { hsl } from 'd3'
import TextField from '@mui/material/TextField'

export interface GraphJsonData {
    [key: string]: number[]
}

interface FlameGraphProps {
    data: GraphJsonData
}

// Function to build the hierarchical data
export interface GraphNodeData {
    id: string
    name: string
    value: number
    call_count: number
    wall_time_avg: number
    wall_time_min: number
    wall_time_max: number
    memory_usage: number
    peak_memory: number
    children: GraphNodeData[]
    filepath?: string
}

function buildFlameGraphData(data: GraphJsonData) {
    // Initialize the root node with 'main()' as the name and id
    const rootId = 'main()'
    const root: GraphNodeData = {
        name: 'main()',
        value: 0,
        call_count: 1,
        wall_time_avg: 0,
        wall_time_min: 0,
        wall_time_max: 0,
        memory_usage: 0,
        peak_memory: 0,
        children: [],
        id: rootId,
    }

    // A map to store nodes by their unique ids for quick lookup
    const nodeMap = new Map<string, GraphNodeData>()
    nodeMap.set(rootId, root)

    // A helper function to recursively add nodes to the tree
    const addToTree = (
        parentNode: GraphNodeData,
        functions: string[],
        accumulatedPath: string,
        call_count: number,
        value: number,
        wall_time_avg: number,
        wall_time_min: number,
        wall_time_max: number,
        memory_usage: number,
        peak_memory: number
    ) => {
        if (functions.length === 0) {
            return
        }

        const func = functions[0]
        const currentPath = `${accumulatedPath}~${func}` // Build the unique id for the current node

        let childNode: GraphNodeData | undefined = nodeMap.get(currentPath)

        if (!childNode) {
            // Create a new node
            childNode = {
                name: func,
                value: 0,
                call_count: 0,
                wall_time_avg: 0,
                wall_time_min: 0,
                wall_time_max: 0,
                memory_usage: 0,
                peak_memory: 0,
                children: [],
                id: currentPath,
            }
            parentNode.children.push(childNode)
            nodeMap.set(currentPath, childNode)
        }

        // Only set the value if this is the complete path we're processing
        if (functions.length === 1) {
            childNode.value = value
            childNode.call_count = call_count
            childNode.wall_time_avg = wall_time_avg
            childNode.wall_time_min = wall_time_min
            childNode.wall_time_max = wall_time_max
            childNode.memory_usage = memory_usage
            childNode.peak_memory = peak_memory
        }

        // Recurse into the next function in the call stack
        addToTree(
            childNode,
            functions.slice(1),
            currentPath,
            call_count,
            value,
            wall_time_avg,
            wall_time_min,
            wall_time_max,
            memory_usage,
            peak_memory
        )
    }

    Object.keys(data).forEach((stackName) => {
        const [call_count, wall_time_total, wall_time_avg, wall_time_min, wall_time_max, memory_usage, peak_memory] =
            data[stackName]
        const functions = stackName.split('~')

        // Add the call stack to the tree, but skip the 'main()' at the start
        addToTree(
            root,
            functions.slice(1),
            rootId,
            call_count,
            wall_time_total,
            wall_time_avg,
            wall_time_min,
            wall_time_max,
            memory_usage,
            peak_memory
        )

        // If this is a direct child of main(), add its value to main()'s total
        if (functions.length === 2) {
            root.value += wall_time_total
        }
    })

    // nodeMap.get(rootId).value = 12000000
    return root
}

function humanizeTime(microseconds: number) {
    const formatter = new Intl.NumberFormat(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })

    const units = [
        { unit: 'hr', divisor: 1000000 * 60 * 60 },
        { unit: 'min', divisor: 1000000 * 60 },
        { unit: 'sec', divisor: 1000000 },
        { unit: 'ms', divisor: 1000 },
        { unit: 'μs', divisor: 1 },
    ]

    for (const { unit, divisor } of units) {
        if (microseconds >= divisor) {
            return formatter.format(microseconds / divisor) + ' ' + unit
        }
    }
    return formatter.format(microseconds) + ' μs'
}

// Calculate the maximum depth of the graph
function calculateGraphHeight(node: GraphNodeData, currentDepth: number = 0): number {
    if (node.children.length === 0) return currentDepth
    return Math.max(...node.children.map((child) => calculateGraphHeight(child, currentDepth + 1)))
}

const PerfbaseFlameGraphComponent = ({ data }: FlameGraphProps) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const flameGraphRef = useRef<FlameGraph | null>(null);

    const [graphData, setGraphData] = useState<GraphNodeData | null>(null)
    const [selectedNode, setSelectedNode] = useState<FlameGraphNode | null>(null)
    const [search, setSearch] = useState<string>("")

    const hashToSoftHSL = (str: string): string => {
        // Generate a simple hash from the string
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = (hash << 5) - hash + str.charCodeAt(i);
            hash |= 0; // Convert to 32bit integer
        }

        // Map hash to HSL values
        const hue = Math.abs(hash) % 360; // Hue in [0, 360)
        const saturation = 60 + (Math.abs(hash) % 40); // Saturation in [50, 100]
        const lightness = 20 + (Math.abs(hash) % 20); // Lightness in [40, 60]

        return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
    };

    const getNamespaceRoot = (name: string): string => {
        const parts = name.split('\\');
        return parts.length > 0 ? parts[0] : name; // Get the first part of the namespace
    };

    // Memoize the color mapper
    const colorMapper = (node: FlameGraphNode, isSelected: boolean, searchString: string) => {
        type ColorMapping = {
            condition: (name: string) => boolean
            color: string
        }

        if (searchString) {
            if (!node.data.name.toLowerCase().includes(searchString.toLowerCase())) {
                return 'lightgrey'
            }
        }

        const isFramework = (name: string) =>
            name.startsWith('Illuminate\\') ||
            name.startsWith('Symfony\\') ||
            name.startsWith('Laravel\\') ||
            name.startsWith('Yii\\') ||
            name.startsWith('CodeIgniter\\');

        const isDatabase = (name: string) =>
            name.startsWith('PDO') ||
            name.startsWith('Doctrine\\') ||
            name.startsWith('MongoDB\\') ||
            name.startsWith('Eloquent\\');

        const isCache = (name: string) =>
            name.startsWith('Redis') ||
            name.startsWith('Memcached') ||
            name.startsWith('Cache\\');

        const isUserspace = (name: string) =>
            name.startsWith('App\\') ||
            name.startsWith('src\\');

        const isInternal = (name: string) =>
            !name.includes('\\');

        const isAuthentication = (name: string) =>
            name.startsWith('Auth\\') ||
            name.startsWith('Firebase\\') ||
            name.startsWith('OAuth\\') ||
            name.startsWith('League\\OAuth2');

        const isMessaging = (name: string) =>
            name.startsWith('PhpAmqpLib\\') ||
            name.startsWith('RabbitMQ\\') ||
            name.startsWith('Kafka\\') ||
            name.startsWith('Queue\\');

        const isTemplating = (name: string) =>
            name.startsWith('Twig\\') ||
            name.startsWith('Blade\\') ||
            name.startsWith('Smarty\\');

        const isTesting = (name: string) =>
            name.startsWith('PHPUnit\\') ||
            name.startsWith('Mockery\\') ||
            name.startsWith('Tests\\');

        const isUtility = (name: string) =>
            name.startsWith('GuzzleHttp\\') ||
            name.startsWith('Carbon\\') ||
            name.startsWith('Monolog\\') ||
            name.startsWith('Faker\\');

        const colorMapping: ColorMapping[] = [
            { condition: isFramework, color: 'red' },
            { condition: isDatabase, color: 'yellow' },
            { condition: isCache, color: 'orange' },
            { condition: isUserspace, color: 'green' },
            { condition: isInternal, color: 'grey' },
            { condition: isAuthentication, color: 'purple' },
            { condition: isMessaging, color: 'blue' },
            { condition: isTemplating, color: 'pink' },
            { condition: isTesting, color: 'cyan' },
            { condition: isUtility, color: 'rgb(80, 80, 80)' }
        ];

        const name: string = node.data.name
        let baseColor = null // Default color if no conditions match

        // Determine the base color based on the node's name
        for (const { condition, color } of colorMapping) {
            if (condition(name)) {
                baseColor = color
                break
            }
        }

        if (!baseColor) {
            baseColor = hashToSoftHSL(getNamespaceRoot(name))
        }

        // Get the maximum depth of the graph (calculate once and reuse)
        const maxDepth = calculateGraphHeight(graphData!) // Implement this function based on your data

        // Define the lightness range (avoid reaching entirely white)
        const minLightness = 20 // Darker shade
        const maxLightness = 70 // Lighter shade (less than 100% to avoid white)

        // Reverse the lightness mapping (from light to dark)
        const depthRatio = node.depth / maxDepth
        const lightness = maxLightness - depthRatio * (maxLightness - minLightness) // Adjust between 90% and 30%

        // Convert the base color to HSL and adjust the lightness
        const colorHSL = hsl(baseColor)
        colorHSL.l = lightness / 100 // D3 uses a scale from 0 to 1 for lightness

        // Adjust the saturation and lightness for faded nodes
        if (!searchString && node.data.fade) {
            colorHSL.s = 0
            // colorHSL.l = 0.5
        }

        if (!searchString && isSelected) {
            colorHSL.h = 0
            colorHSL.s = 0.1
            colorHSL.l = 0.35
        }

        return colorHSL.toString() // Return the adjusted color as a string
    }

    // Memoize the render function
    const renderFlameGraph = () => {
        if (!containerRef.current || !graphData) return

        // Clear previous graph
        select(containerRef.current).selectAll('svg').remove()

        // Create new flame graph
        const width = containerRef.current.clientWidth
        const depth = calculateGraphHeight(graphData)
        const cellHeight = 18
        const padding = 24
        const height = (depth + 1) * cellHeight + padding

        const numberFormatter = new Intl.NumberFormat(undefined)

        const fg = new FlameGraph()
        fg.w = width
        fg.h = height
        fg.c = cellHeight
        fg.selfValue = false
        fg.inverted = true
        fg.minFrameSize = 2
        fg.searchString = search

        fg.getNameFormatted = (node: FlameGraphNode) => {
            const input = `<span>${node.data.name}</span>`
            const openTag = '<span style="color: white; font-weight: bolder">'
            const closeTag = '</span>'

            if (!node.data.name.includes('::')) {
                return `${openTag}${node.data.name}${closeTag}`
            }

            // Highlight the class and method names
            return input.replace(
                /([A-Za-z_]\w*)::([A-Za-z_]\w*)(\(\))?/,
                (_, cls, method, brackets) => `${openTag}${cls}::${method}${brackets ?? ''}${closeTag}`
            )
        }

        // fg.setTooltip(
        //     defaultFlamegraphTooltip().text((node: FlameGraphNode) => {
        //         return `${node.data.name} (${humanizeTime(node.value)}, ${numberFormatter.format(node.data.call_count)} calls)`
        //     })
        // )
        fg.setClickHandler((node: FlameGraphNode) => {
            setSelectedNode(node)
        })

        fg.setLabelHandler((node: FlameGraphNode) => {
            return `${node.data.name} (${humanizeTime(node.value)}, ${numberFormatter.format(node.data.call_count)} calls)`
        })

        fg.setColorMapper(colorMapper)

        const selection = select(containerRef.current).datum(graphData)
        fg.render(selection)

        // Return the FlameGraph instance for cleanup

        flameGraphRef.current = fg

        return fg
    }

    useEffect(() => {
        const processedData = buildFlameGraphData(data)
        setGraphData(processedData)
    }, [data])


    useEffect(() => {
        const graph = renderFlameGraph(); // Assuming this returns the flame graph instance
        flameGraphRef.current = graph!; // Update the ref with the flame graph instance

        /*
        const handleResize = debounce(() => {
            if (!containerRef.current || !flameGraphRef.current) return
            flameGraphRef.current.w = containerRef.current.clientWidth;
            flameGraphRef.current.update();
        }, 200);

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
            handleResize.clear(); // Clear the debounced function on cleanup
        };
         */
        const handleResize = () => {
            if (!containerRef.current || !flameGraphRef.current) return
            flameGraphRef.current.w = containerRef.current.clientWidth;
            flameGraphRef.current.update();
        };

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [graphData]);

    const changeSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value)
        flameGraphRef.current?.search(event.target.value)
    }

    return (
        <div>
            <TextField label="Search" variant="outlined" size="small" sx={{ mr: 1 }} onChange={changeSearch} />
            <h2>GET /example/hello {search}</h2>
            <div
                ref={containerRef}
                style={{
                    width: '100%',
                    minHeight: '100px', // Minimum height before data loads
                    fontFamily: 'Arial, sans-serif',
                }}
            />
            {selectedNode && (
                <div style={{ padding: '1rem' }}>
                    <h3>Selected Function: {selectedNode.data.name}</h3>
                    <ul>
                        <li>Wall Time: {humanizeTime(selectedNode.value)}</li>
                        <li>Call Count: {selectedNode.data.call_count}</li>
                        <li>Min Time per Call: {humanizeTime(selectedNode.data.wall_time_min)}</li>
                        <li>Max Time per Call: {humanizeTime(selectedNode.data.wall_time_max)}</li>
                        <li>Average Time per Call: {humanizeTime(selectedNode.data.wall_time_avg)}</li>
                    </ul>
                </div>
            )}
        </div>
    )
}

export const PerfbaseFlameGraph = memo(PerfbaseFlameGraphComponent, (prevProps, nextProps) => {
    return JSON.stringify(prevProps.data) === JSON.stringify(nextProps.data)
})
