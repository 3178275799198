import Loadable from '@/components/Loadable'
// import ProtectedRoute from '@/components/routing/ProtectedRoute'
import Dashboard from '../layout/Dashboard'
import ComponentFlameGraph from '../pages/component-overview/flamegraph'

// Lazy-loaded components
const Color = Loadable(() => import('../pages/component-overview/color'))
const Typography = Loadable(() => import('../pages/component-overview/typography'))
const Shadow = Loadable(() => import('../pages/component-overview/shadows'))
const DashboardDefault = Loadable(() => import('../pages/dashboard/index'))
const SamplePage = Loadable(() => import('../pages/extra-pages/sample-page'))

const MainRoutes = {
    path: '/',
    element: (
        // <ProtectedRoute>
        <Dashboard />
        // </ProtectedRoute>
    ),
    children: [
        {
            index: true,
            element: <DashboardDefault />,
        },
        {
            path: 'color',
            element: <Color />,
        },
        {
            path: 'flamegraph',
            element: <ComponentFlameGraph />,
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <DashboardDefault />,
                },
            ],
        },
        {
            path: 'sample-page',
            element: <SamplePage />,
        },
        {
            path: 'shadow',
            element: <Shadow />,
        },
        {
            path: 'typography',
            element: <Typography />,
        },
    ],
}

export default MainRoutes
