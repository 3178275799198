// material-ui
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

const DrawerHeaderStyled = styled(Box, { shouldForwardProp: (prop: string) => prop !== 'open' })(({
    open,
}: {
    open: boolean
}) => {
    const theme = useTheme()
    return {
        ...theme.mixins.toolbar,
        display: 'flex',
        alignItems: 'center',
        justifyContent: open ? 'flex-start' : 'center',
        paddingLeft: theme.spacing(open ? 3 : 0),
    }
})

export default DrawerHeaderStyled
